import { z } from 'zod';
import { OpenAIModels } from '../ai';

export const ActimoLanguageTypeSchema = z.enum([
  'no change',
  'da-DK',
  'en-US',
  'es-ES',
  'et-EE',
  'nl-NL',
  'fr-FR',
  'fi-FI',
  'de-DE',
  'hu-HU',
  'it-IT',
  'ja-JP',
  'nb-NO',
  'pt-BR',
  'pl-PL',
  'ro-RO',
  'sv-SE',
  'ru-RU',
  'tr-TR',
]);

const countWords = (text: string) => text.trim().split(/\s+/).length;

export const BaseTextEnhancerSchema = z.object({
  text: z.string().min(10),
  tone: z.enum([
    'no change',
    'conversational',
    'empathetic',
    'formal',
    'humorous',
    'informative',
    'inspirational',
    'neutral',
    'playful',
    'reflective',
  ]),
  length: z.enum(['no change', 'extend', 'shorten']),
  language: ActimoLanguageTypeSchema,
  companyDescription: z.string().optional(),
});

export const TextEnhancerSchema = BaseTextEnhancerSchema.refine(
  (data) => {
    const textWordCount = countWords(data.text);
    const companyDescriptionWordCount = data.companyDescription ? countWords(data.companyDescription) : 0;
    return textWordCount >= 10 && textWordCount + companyDescriptionWordCount <= 500;
  },
  {
    message: 'The text should contain between 10 and 500 words',
    path: ['text', 'companyDescription'],
  },
);

export const EnhanceOptionsSchema = BaseTextEnhancerSchema.omit({ text: true });
export type EnhanceOptions = z.infer<typeof EnhanceOptionsSchema>;

export type TextEnhancerServiceConfig = {
  key?: 'gpt3' | 'gpt4';
  deploymentName: string;
  modelName: OpenAIModels;
  endpoint: string;
  apiKey: string;
  defaultLanguage: string;
};
