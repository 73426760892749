import { toSignal } from '@angular/core/rxjs-interop';
import { BrowserService } from '@ao/utilities';
// @ts-strict
import { BreakpointObserver } from '@angular/cdk/layout';
import { Inject, Injectable } from '@angular/core';
import { DESKTOP_MIN_WITH, MessageModalService } from '@ao/common-ui';
import {
  Contact,
  ContactStatus,
  FeatureFlagKey,
  FeatureFlags,
  GenericMessageToast,
  LOADING_STATE,
  MessageError,
  MessageTrackingAttributes,
  ModalStatus,
  PendingMessage,
  ProfileSettings,
  SupportSettings,
  Theme,
  UpdateContactInfoModel,
  ViewerSettingsFE,
} from '@ao/data-models';
import { WINDOW, onceDefined } from '@ao/utilities';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest, combineLatestWith, map } from 'rxjs';
import * as viewerCoreActions from './+state/actions/viewer-core-store.actions';
import * as viewerCoreSelectors from './+state/selectors/viewer-core-store.selectors';

@Injectable({ providedIn: 'root' })
export class ViewerCoreFacade {
  loadingState$: Observable<LOADING_STATE> = this.store.pipe(select(viewerCoreSelectors.selectLoadingState));
  // Core
  origin$: Observable<string> = this.store.pipe(select(viewerCoreSelectors.selectCurrentOrigin));
  keycode$: Observable<string> = this.store.pipe(select(viewerCoreSelectors.selectCurrentKeycode));
  pageId$: Observable<number> = this.store.pipe(select(viewerCoreSelectors.selectCurrentPageId));
  queryParams$: Observable<Record<string, string>> = this.store.pipe(
    select(viewerCoreSelectors.selectCurrentQueryParams),
  );
  extraState$: Observable<Record<string, string>> = this.store.pipe(
    select(viewerCoreSelectors.selectCurrentRouterExtraState),
  );
  toast$: Observable<GenericMessageToast> = this.store.pipe(select(viewerCoreSelectors.selectToast));

  // Errors
  errorState$: Observable<MessageError> = this.store.pipe(select(viewerCoreSelectors.selectErrorState));
  errorCode$ = this.store.select(viewerCoreSelectors.selectErrorCode);
  errorLanguage$ = this.store.select(viewerCoreSelectors.selectErrorLanguage);
  errorSupport$ = this.store.select(viewerCoreSelectors.selectErrorSupport);
  errorTheme$ = this.store.select(viewerCoreSelectors.selectErrorTheme);

  // Company Context
  supportSettings$: Observable<SupportSettings> = this.store.pipe(select(viewerCoreSelectors.selectSupportSettings));
  termsOfUse$: Observable<{ enabled: boolean; customText: string }> = this.store.pipe(
    select(viewerCoreSelectors.selectTermsOfUse),
  );
  featureFlags$: Observable<FeatureFlags> = this.store.pipe(select(viewerCoreSelectors.selectFeatureFlags));
  menuExpandedDesktop$: Observable<boolean> = this.store.pipe(select(viewerCoreSelectors.selectMenuExpandedDesktop));
  isS3DirectUploadEnabled$: Observable<boolean> = this.store.pipe(select(viewerCoreSelectors.selects3UploadEnabled));
  chatEnabled$: Observable<boolean> = this.store.pipe(select(viewerCoreSelectors.selectChatEnabled));

  selectPageHasHeader$: Observable<boolean> = this.store.pipe(select(viewerCoreSelectors.selectPageHasHeader));
  selectPageHasNavBar$: Observable<boolean> = this.store.pipe(select(viewerCoreSelectors.selectPageHasNavBar));

  socialEnabled$: Observable<boolean> = this.store.pipe(select(viewerCoreSelectors.selectSocialEnabled));
  socialName$: Observable<string> = this.store.pipe(select(viewerCoreSelectors.selectSocialName));
  directoryIsEnabled$: Observable<boolean> = this.store.pipe(select(viewerCoreSelectors.selectDirectoryIsEnabled));

  notificationsEnabled$: Observable<boolean> = this.store.pipe(select(viewerCoreSelectors.selectNotificationsEnabled));
  voiceNotesEnabled$: Observable<boolean> = this.store.pipe(select(viewerCoreSelectors.selectVoiceNotesEnabled));
  trackingAttributes$: Observable<MessageTrackingAttributes> = this.store.pipe(
    select(viewerCoreSelectors.selectTrackingAttributes),
  );

  taskManagementEnabled$: Observable<boolean> = this.store.pipe(
    select(viewerCoreSelectors.selectTaskManagementEnabled),
  );
  saveContentEnabled$: Observable<boolean> = this.store.pipe(select(viewerCoreSelectors.selectSaveContentEnabled));

  // User Context
  unreadCounts$: Observable<Record<number, number>> = this.store.pipe(select(viewerCoreSelectors.selectUnreadCounts));
  contact$: Observable<Contact> = this.store.pipe(select(viewerCoreSelectors.selectContact));
  contactInitials$: Observable<string> = this.store.pipe(select(viewerCoreSelectors.selectContactInitials));
  contactId$: Observable<number> = this.store.pipe(select(viewerCoreSelectors.selectContactId));
  contactAuthCode$: Observable<string> = this.store.pipe(select(viewerCoreSelectors.selectContactAuthCode));
  profileSettings$: Observable<ProfileSettings> = this.store.pipe(select(viewerCoreSelectors.selectProfileSettings));
  pendingRecurringMsgs$: Observable<PendingMessage[]> = this.store.pipe(
    select(viewerCoreSelectors.selectPendingRecurringMsgs),
  );
  employees$: Observable<number[]> = this.store.pipe(select(viewerCoreSelectors.selectEmployees));
  relationships$: Observable<Record<string, Contact>> = this.store.pipe(
    select(viewerCoreSelectors.selectRelationships),
  );
  contactModalStatus$: Observable<ModalStatus> = this.store.pipe(select(viewerCoreSelectors.selectContactModalStatus));
  contactStatus$: Observable<ContactStatus> = this.store.pipe(select(viewerCoreSelectors.selectContactStatus));
  userIsManager$: Observable<boolean> = this.store.pipe(select(viewerCoreSelectors.selectUserIsManager));
  autoTranslationEnabled$: Observable<boolean> = this.store.pipe(
    select(viewerCoreSelectors.selectAutoTranslationEnabled),
  );
  systemAllowPush$: Observable<boolean> = this.store.pipe(select(viewerCoreSelectors.selectSystemAllowPush));
  contactDeviceTypeGroup$: Observable<string> = this.store.pipe(
    select(viewerCoreSelectors.selectContactDeviceTypeGroup),
  );
  lastSeenNotifications$: Observable<Date> = this.store.select(viewerCoreSelectors.selectLastSeenNotifications);
  userLang$: Observable<string> = this.store.select(viewerCoreSelectors.selectUserLang);
  contactOptOut$: Observable<boolean> = this.store.pipe(select(viewerCoreSelectors.selectContactOptOut));
  messageCountry$: Observable<string> = this.store.pipe(select(viewerCoreSelectors.selectMessageCountry));

  // Viewer settings
  viewerSettings$: Observable<ViewerSettingsFE | undefined> = this.store.pipe(
    select(viewerCoreSelectors.selectViewerSettings),
  );
  chatUserUid$: Observable<string> = this.store.pipe(select(viewerCoreSelectors.selectViewerSettingsChatUserId));
  defaultTheme$: Observable<Theme> = this.store.pipe(select(viewerCoreSelectors.selectDefaultTheme));

  // Native
  nativeStatusBarTheme = toSignal(this.store.pipe(select(viewerCoreSelectors.selectStatusBarTheme)));

  // Feature flags
  featureFlagByKey$ = (key: FeatureFlagKey): Observable<boolean> =>
    this.store.select(viewerCoreSelectors.getFeatureFlagByKey({ key }));

  chatLink$ = combineLatest([this.chatEnabled$, this.keycode$, this.origin$]).pipe(
    map(([chatEnabled, keycode, origin]) => (chatEnabled ? ['/', origin, keycode, 'chat'] : null)),
  );

  tasksLink$ = combineLatest([this.taskManagementEnabled$, this.keycode$, this.origin$]).pipe(
    map(([taskFFActive, keycode, origin]) => {
      return taskFFActive ? ['/', origin, keycode, 'task'] : null;
    }),
  );

  // navbar UI
  navbarHidden$ = this.store.pipe(select(viewerCoreSelectors.selectNavbarHidden));

  // layout Android native events
  handleAndroidLayoutMessages() {
    this.store.dispatch(viewerCoreActions.HandleAndroidLayoutMessages());
  }

  constructor(
    private store: Store,
    @Inject(WINDOW) private window: Window,
    private messageModalService: MessageModalService,
    private browserService: BrowserService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.window.addEventListener('chunkError updates', () => {
      const ref = this.messageModalService.confirm({
        title: 'Update available',
        text: 'An update of the Actimo app is available. Please reload your page to get the latest improvements.',
        confirmButtonText: 'Reload page',
        dismissButtonText: 'Ignore',
      });
      ref.confirmed.subscribe(() => {
        const url = new URL(window.location.href);
        url.searchParams.set('uCache', Date.now().toString());
        window.location.href = url.href.toString();
      });
    });
  }

  loadAppContext(origin: string, keycode: string, queryParams?: Record<string, string>): void {
    this.store.dispatch(viewerCoreActions.LoadAppContext({ origin, keycode, queryParams }));
  }

  resetAppContext(): void {
    this.store.dispatch(viewerCoreActions.ResetAppContext());
  }

  loadClientBasicConfig(redirectUrl?: string): void {
    this.store.dispatch(viewerCoreActions.LoadClientBasicConfig({ redirectUrl }));
  }

  setAppReady(): void {
    this.store.dispatch(viewerCoreActions.AppReady());
  }

  triggerError(error: { status: string; code: string }): void {
    this.store.dispatch(viewerCoreActions.TriggerError({ error }));
  }

  resetNative({ error, source }: { error?: unknown; source: string }): void {
    this.store.dispatch(viewerCoreActions.ResetNative({ error, source }));
  }

  handleNativePostMessages(): void {
    this.store.dispatch(viewerCoreActions.HandleNativePostMessages());
  }
  logout(keycode: string, origin: string): void {
    this.store.dispatch(viewerCoreActions.Logout({ origin, keycode }));
  }

  loadInsights(): void {
    this.store.dispatch(viewerCoreActions.LoadInsights());
  }

  updateContact(keycode: string, contactId: number): void {
    this.store.dispatch(viewerCoreActions.UpdateContact({ keycode, contactId }));
  }

  updateContactInfo({
    id,
    newValue,
    sendHomePage,
    avatar,
    showToast,
  }: {
    id: number;
    newValue: UpdateContactInfoModel['contactFields'];
    sendHomePage?: boolean;
    avatar?: Blob;
    showToast?: boolean;
  }): void {
    this.store.dispatch(
      viewerCoreActions.UpdateContactInfo({
        id,
        newValue,
        sendHomePage,
        avatar,
        showToast,
      }),
    );
  }

  updateModalStatus(modalStatus: ModalStatus) {
    this.store.dispatch(viewerCoreActions.UpdateModalStatus({ modalStatus }));
  }

  updateContactStatus(contactStatus: ContactStatus) {
    this.store.dispatch(viewerCoreActions.UpdateContactStatus({ contactStatus }));
  }

  updateSubscriptionStatus(isSubscribing: boolean) {
    this.store.dispatch(viewerCoreActions.UpdateSubscriptionStatus({ isSubscribing }));
  }

  updateSystemAllowPushNotification(system_allow_push: boolean) {
    this.store.dispatch(viewerCoreActions.UpdateSystemAllowPushNotification({ system_allow_push }));
  }

  showToast(toast: GenericMessageToast): void {
    this.store.dispatch(viewerCoreActions.ShowGenericMessageToast({ toast }));
  }

  dismissToast(): void {
    this.store.dispatch(viewerCoreActions.DismissGenericMessageToast());
  }

  createContact(formData: any) {
    this.store.dispatch(viewerCoreActions.CreateContact({ formData }));
  }

  resetCreateContact() {
    this.store.dispatch(viewerCoreActions.ResetCreateContact());
  }

  chatFlagAndChatDefaultEnabled$(): Observable<boolean> {
    return this.chatEnabled$.pipe(
      onceDefined(),
      combineLatestWith(this.featureFlagByKey$('chat').pipe(onceDefined())),
      map(([chatEnabled, chatFlagEnabled]) => {
        return chatEnabled && chatFlagEnabled;
      }),
    );
  }

  loadViewerSettings() {
    this.store.dispatch(viewerCoreActions.LoadViewerSettings());
  }
  hideNavbar() {
    this.store.dispatch(viewerCoreActions.HideNavbar());
  }
  showNavbar() {
    this.store.dispatch(viewerCoreActions.ShowNavbar());
  }
  loadUnreadCount() {
    this.store.dispatch(viewerCoreActions.LoadUnreadCount());
  }
  getHomepage$(): Observable<{
    messageId: number;
    keycode: string;
    origin: string;
  }> {
    return this.store.select(viewerCoreSelectors.selectViewerSettingsHomepage);
  }

  setStatusBarTheme(themeBgColor: string, invertResult = false) {
    const statusBarTheme = this.browserService.setDeviceStatusBarTheme(themeBgColor, invertResult);
    this.dispatchStoreStatusBarThemeUpdate(statusBarTheme);
  }
  private dispatchStoreStatusBarThemeUpdate(statusBarTheme: 'light' | 'dark') {
    this.store.dispatch(viewerCoreActions.setStatusBarThemeSuccess({ statusBarTheme }));
  }
  setStatusBarFontColorFromLatest() {
    this.browserService.setDeviceStatusBarThemeFromLatest(this.nativeStatusBarTheme());
  }

  smallScreen$ = this.breakpointObserver.observe([`(max-width: ${DESKTOP_MIN_WITH}px)`]).pipe(
    map(({ matches }) => {
      const isSmall = matches;
      return isSmall || this.browserService.isMobileOrTabletOS();
    }),
  );

  optimizedDesktop = toSignal(this.featureFlagByKey$('desktop_layout'));
  optimizedDesktopFeature$ = this.smallScreen$.pipe(map((isSmallScreen) => !isSmallScreen && this.optimizedDesktop()));
}
